<template>
  <div>
    <a-card title="批次报表">
      <a-row :gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="产品编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%;" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">{{item.name}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.has_stock" placeholder="库存状态" allowClear style="width: 100%;" @change="search">
            <a-select-option  :value="true">有库存</a-select-option>
            <a-select-option  :value="false">无库存</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 300px; margin-bottom: 12px;">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="openFormModal(item)">出入库记录</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal ref="batchDetail" v-model="visible" :form="batchid" @cancel="cancel"  />
  </div>
</template>

<script>
import { warehousesOption } from '@/api/option'
import { batchsReportList,  } from '@/api/report'
import { exportExcel } from '@/utils/excel'

export default {
  name: "Warehouse",
  components: {
      FormModal: () => import('./FormModal.vue'),
    },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "批次号",
          dataIndex: "number",
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
        },
        {
          title: "批次数量",
          dataIndex: "total_quantity",
        },
        {
          title: "批次剩余数量",
          dataIndex: "remain_quantity",
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
        },
        {
          title: "产品名称",
          dataIndex: "goods_name",
          sorter: true,
        },
        {
          title: "仓库",
          dataIndex: "warehouse_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "库存状态",
          dataIndex: "has_stock",
          customRender: (value, item, index) => {
            return item.has_stock ? '有' : '无'
          },
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
      ],
      searchForm: { search: "", page: 1, pageSize: 20 },
      pagination: { current: 1, total: 0, pageSize: 20 },
      loading: false,
      visible: false,
      items: [],
      warehouseItems: 0,
      batchid: 0,
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      batchsReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
      this.visible = false;
    },
    openFormModal(item) {
        this.targetItem = { ...item };
        this.batchid = this.targetItem.id;
        this.visible = true;
        this.$refs.batchDetail.listout(this.targetItem.id);
    },
    exportExcel() {
        inventoryFlowExport(this.searchForm).then(resp => {
          exportExcel(resp.data, '批次报表');
        }).catch(err => {
          this.$message.error(err.response.data.error);
        });
      },
     cancel() {
         this.visible = false;
     },

  },
  mounted() {
    this.initialize();
  },
};
</script>
